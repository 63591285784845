var Sticky = Class.extend( {
	init: function ( options ) {
		var that = this;

		this.container = $( 'body' );
		this.header = this.container.find( '.header' );
		this.parent = options.parent;
		this.sticky = options.element;

		this.offsetTop = this.sticky.offset().top - 30;
		this.offsetleft = this.parent.left;
		this.elmWidth = this.parent.width() - this.parent.find( '.listing-team--member' ).width();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;


		W.window.on( Event.RESIZE, $.proxy( this._onResize, this ) );
		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_onMouseWheel: function () {
		var that = this;
		var scrollTop = $( window ).scrollTop();

		if ( W.ww > 970 ) {

			if ( scrollTop >= ( this.offsetTop - that.header.height() ) ) {

				if ( scrollTop > ( that.parent.offset().top + that.parent.outerHeight() - that.sticky.height() ) ) {

					that.sticky.removeAttr( 'style' );
					that.sticky.css( {
						'position': 'absolute',
						'top': 'inherit',
						'bottom': '30x',
						'width': this.elmWidth + 'px'
					} );

				} else {

					that.sticky.removeAttr( 'style' );
					that.sticky.css( {
						'position': 'fixed',
						'left': this.offsetleft + 'px',
						'top': that.header.height() + 30 + 'px',
						'width': this.elmWidth + 'px'
					} );

				}

			} else {
				that.sticky.removeAttr( 'style' );
			}

		}
	},


	_onResize: function () {
		var that = this;

		this.offsetTop = this.sticky.offset().top - 30;
		this.offsetleft = this.parent;
		this.elmWidth = this.parent.width() - this.parent.find( '.listing-team--member' ).width();

		console.log( this.offsetTop );

		console.log( this.elmWidth );

		this._onMouseWheel();
	}
} );
