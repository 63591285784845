var Filters = Class.extend( {
	init: function ( fixed ) {
		var that = this;

		this.container = $( ".form--filters" );
		this.field = this.container.find( '.field' );
		this.select = this.container.find( 'select' );
		this.selectSingle = this.container.find( '.select--simple' );
		this.selectMulti = this.container.find( '.select--multi' );
		this.removeSelection = this.container.find( '.remove-selection' );
		this.isFixed = fixed || false;
		this.minYearDefault = parseInt( min_date );
		this.removeAllSection = false;
		this.selectGroup = "";

		this.offset = this.container.offset().top;
		this.isLoad = false;

		this._initEvents();
		this._onMouseWheel();
		this.gMpas = new gMaps();
	},

	_setDefaultYear: function ( type ) {
		var that = this;
		var options = "";

		if ( type === 'date-start' ) {
			options += '<option value="">' + selectOptions.start + '</option>';

			for ( i = parseInt( min_date ); i <= parseInt( current_year ); i++ ) {
				options += '<option value="' + i + '">' + i + '</option>';
			}
		} else if ( type === 'date-end' ) {
			options += '<option value="">' + selectOptions.end + '</option>';

			for ( i = parseInt( current_year ); i >= parseInt( min_date ); i-- ) {
				options += '<option value="' + i + '">' + i + '</option>';
			}
		}

		this.container.find( '.select--simple[name="' + type + '"] option' ).remove();
		this.container.find( '.select--simple[name="' + type + '"]' ).append( options );
		this.container.find( '.select--simple[name="' + type + '"]' ).dropkick( 'refresh' );
	},

	_setMinYear: function ( start, end ) {
		var options = "";

		if ( start && start !== undefined ) {
			options = "";
			options += '<option value="">' + selectOptions.end + '</option>';

			for ( i = parseInt( current_year ); i >= parseInt( start ); i-- ) {
				if ( end && end !== undefined && i === parseInt( end ) ) {
					options += '<option selected value="' + i + '">' + i + '</option>';
				} else {
					options += '<option value="' + i + '">' + i + '</option>';
				}
			}

			this.container.find( '.select--simple[name="date-end"] option' ).remove();
			this.container.find( '.select--simple[name="date-end"]' ).append( options );
			this.container.find( '.select--simple[name="date-end"]' ).dropkick( 'refresh' );
		}

		if ( end && end !== undefined ) {
			options = "";
			options += '<option value="">' + selectOptions.start + '</option>';

			for ( i = parseInt( min_date ); i <= parseInt( end ); i++ ) {
				if ( start && start !== undefined && i === parseInt( start ) ) {
					options += '<option selected value="' + i + '">' + i + '</option>';
				} else {
					options += '<option value="' + i + '">' + i + '</option>';
				}
			}

			this.container.find( '.select--simple[name="date-start"] option' ).remove();
			this.container.find( '.select--simple[name="date-start"]' ).append( options );
			this.container.find( '.select--simple[name="date-start"]' ).dropkick( 'refresh' );
		}
	},

	_initEvents: function () {
		var that = this;

		var query = window.location.search.substring( 1 );
		var vars = query.split( "&" );

		if ( vars.length > 0 && vars[ 0 ] !== "" ) {
			this.removeSelection.removeClass( 'remove-selection--is-hide' );
		}

		this.selectMulti.each( function () {

			var elm = $( this );

			if ( $( this ).attr( 'name' ) === "activities" ) {

				$( this ).multipleSelect( {
					placeholder: $( this ).attr( 'data-msplaceholder' ),
					single: false,
					multiple: false,
					selectAll: true,
					maxHeight: 275,
					selectAllText: multiSelectOptions.selectAllText,
					allSelected: $( this ).attr( 'data-msplaceholder' ),
					countSelected: multiSelectOptions.countSelected,
					onClick: function ( view ) {

						var select = [];
						var class_name = elm.attr( 'class' ).split( " " );
						var group = $( 'input[value="' + view.value + '"]' ).attr( 'data-group' );

						if ( that.selectGroup === group ) {
							$.each( elm.multipleSelect( 'getSelects' ), function ( i, value ) {
								if ( view.value !== value && $( 'input[value="' + value + '"]' ).attr( 'data-group' ) === group ) {
									select.push( value );
								}
							} );

							if ( view.checked === true ) {
								select.push( view.value );
							}
						} else {
							if ( view.checked === true ) {
								select = [ view.value ];
							} else {
								select = [];
							}
						}
						elm.multipleSelect( 'setSelects', [] );
						elm.multipleSelect( 'setSelects', select );
						that._setSelectData( select, true );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}

						that.selectGroup = group;
					},

					onCheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						that.container.submit();
					},
					onUncheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );

						if ( !that.removeAllSection ) {
							that.container.submit();
						}
					},
					onOptgroupClick: function ( view ) {
						var class_name = elm.attr( 'class' ).split( " " );
						var select = [];

						elm.multipleSelect( 'setSelects', [] );

						$.each( view.children, function () {
							select.push( $( this ).val() );
						} );

						elm.multipleSelect( 'setSelects', select );
						that._setSelectData( select, true );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}
					}
				} );

			} else if ( $( this ).attr( 'name' ) === "fonctions" || $( this ).attr( 'name' ) === "structures" || $( this ).attr( 'name' ) === "missions" ) {
				$( this ).multipleSelect( {
					placeholder: $( this ).attr( 'data-msplaceholder' ),
					selectAll: true,
					filter: false,
					selectAllText: multiSelectOptions.selectAllText,
					allSelected: $( this ).attr( 'data-msplaceholder' ),
					countSelected: multiSelectOptions.countSelected,
					selectAllDelimiter: [],
					onClick: function ( view ) {
						that.container.submit();

						var class_name = elm.attr( 'class' ).split( " " );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}
					},
					onOptgroupClick: function ( view ) {
						var class_name = elm.attr( 'class' ).split( " " );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}

						that.container.submit();
					},
					onCheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						that.container.submit();
					},
					onUncheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );

						if ( !that.removeAllSection ) {
							that.container.submit();
						}
					}

				} );
			} else {
				$( this ).multipleSelect( {
					placeholder: $( this ).attr( 'data-msplaceholder' ),
					selectAll: true,
					filter: false,
					selectAllText: multiSelectOptions.selectAllText,
					allSelected: $( this ).attr( 'data-msplaceholder' ),
					countSelected: multiSelectOptions.countSelected,
					selectAllDelimiter: [],
					onClick: function ( view ) {
						that.container.submit();

						var class_name = elm.attr( 'class' ).split( " " );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}
					},
					onOptgroupClick: function ( view ) {
						var class_name = elm.attr( 'class' ).split( " " );

						if ( elm.multipleSelect( 'getSelects' ).length > 0 ) {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
						} else {
							$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						}

						that.container.submit();
					},
					onCheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );
						that.container.submit();
					},
					onUncheckAll: function () {
						var class_name = elm.attr( 'class' ).split( " " );
						elm.multipleSelect( 'setSelects', [] );
						$( '.' + class_name[ 3 ] ).find( '.ms-select-all' ).removeClass( 'ms-select-all--open' );

						if ( !that.removeAllSection ) {
							that.container.submit();
						}
					}

				} );

			}
		} );

		this.selectSingle.dropkick( {
			mobile: true,
			change: function ( value, label ) {
				that.container.submit();
			}
		} );

		if ( this.isFixed ) {
			W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
		}

		$( 'label.optgroup ' ).each( function () {
			var group = $( this ).attr( 'data-group' );
			var parent = $( this ).parents( 'ul' );

			if ( parent.find( 'input[data-group="' + group + '"]' ).length === parent.find( 'input[data-group="' + group + '"]' ).parents( '.selected' ).length ) {
				var label = parent.find( 'label.optgroup[data-group="' + group + '"]' );

				parent.find( '.ms-select-all' ).addClass( 'ms-select-all--open' );
				label.find( 'input' ).prop( "checked", true );
				label.parent().addClass( 'selected' );
			}
		} );

		// this.select.on( Event.CHANGE, function () {
		// 	console.log( 'change' );
		// 	that.container.submit();
		// } );

		this.container.on( "submit", function ( e ) {
			e.preventDefault();
			that._serializeData( that.container.serializeArray() );
		} );

		this.removeSelection.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			if ( !$( this ).hasClass( 'remove-selection--is-hide' ) ) {
				$( this ).addClass( 'remove-selection--is-hide' );
				that._setDefaultYear( 'date-start' );
				that._setDefaultYear( 'date-end' );
				that.removeAllSection = true;

				that.selectSingle.dropkick( "reset", true );
				that.selectMulti.multipleSelect( 'uncheckAll' );
				that._setSelectData( [], true );
			}
		} );
	},

	_onMouseWheel: function () {
		var scrollTop = $( window ).scrollTop();

		if ( device === "desktop" ) {
			this.container.parent().css( 'padding-top', this.container.outerHeight() );

			if ( scrollTop >= this.offset ) {
				this.container.addClass( 'form--filters--is-fixed' );

				if ( $( 'header' ).hasClass( 'header--is-hide' ) ) {
					this.container.css( 'top', 0 );
				} else {
					this.container.css( 'top', $( 'header' ).outerHeight() );
				}

			} else {
				this.container.removeClass( 'form--filters--is-fixed' );
				this.container.css( 'top', 0 );
			}

			this.container.removeClass( 'form--filters--no-tansition' );

			this._hasScroll( scrollTop );
		}
	},

	_hasScroll: function ( st ) {
		var that = this;

		// Make sure they scroll more than delta
		if ( Math.abs( that.lastScrollTop - st ) <= this.offset + 30 )
			return;

		// If they scrolled down and are past the navbar, add class .header--is-hide.
		// This is necessary so you never see what is "behind" the navbar.
		if ( st > that.lastScrollTop && st > this.offset ) {
			// Scroll Down
			that.container.addClass( 'form--filters--is-hide' );
		} else {
			// Scroll Up
			if ( st + $( window ).height() < $( document ).height() ) {
				that.container.removeClass( 'form--filters--is-hide' );
			}
		}

		that.lastScrollTop = st;
	},

	_getData: function ( url ) {
		var that = this;

		$( '.loader' ).addClass( 'loader--is-active' );

		$.ajax( {
			url: url,
			dataType: "html",
			type: 'GET',
			context: document.body,
			success: function ( data, statut ) {
				var response = $( data );
				var content = response.find( '.listing-projects' );
				var error = response.find( '.notice' );

				$( '.notice' ).remove();

				$( '.content' ).attr( 'data-json', response.find( '.content' ).attr( 'data-json' ) );
				$( '.listing-projects' ).attr( 'data-pages', content.attr( 'data-pages' ) );
				$( '.listing-projects' ).attr( 'data-page', content.attr( 'data-page' ) );
				$( '.listing-projects' ).attr( 'data-url', content.attr( 'data-url' ) );

				$( '.listing-projects' ).find( '.listing-projects__item' ).fadeOut( 100 );
				$( '.listing-projects' ).find( '.listing-projects__item' ).remove();

				$( '.infosbar__count-projects' ).find( 'strong' ).html( response.find( '.infosbar__count-projects strong' ).html() );

				that.gMpas._filtersMarker();

				if ( error.length > 0 ) {
					that._showError( error );
				} else {
					that._setListing( content );
				}

				that.isLoad = false;
				$( '.loader' ).removeClass( 'loader--is-active' );
			}
		} );
	},

	_setListing: function ( data ) {

		data.find( '.listing-projects__item' ).each( function ( index ) {
			$( '.listing-projects' ).append( $( this ) );
		} );
	},

	_showError: function ( error ) {
		error.insertAfter( $( '.form--filters' ) );
	},

	_changeURL: function ( params ) {
		var currentURL = window.location.pathname;

		var url = "";

		if ( params !== "" ) {
			url = currentURL + '?' + params;
		} else {
			url = currentURL;
		}

		if ( history.pushState ) {
			history.pushState( null, null, url );
		}

		$.session.set( 'last_url', url );
		$.session.set( 'type', 'projects' );

		return url;
	},

	_serializeData: function ( data ) {

		var that = this;
		var start = false;
		var params = {};

		if ( this.isLoad === true ) {
			return false;
		}

		this.isLoad = true;

		$( data ).each( function ( index, field ) {

			if ( field.value !== "" && field.name !== "activities" ) {
				var name = field.name;

				if ( params[ name ] !== undefined ) {
					params[ name ] += ' ' + field.value;
				} else {
					params[ name ] = field.value;
				}
			}
		} );

		$.each( $( '.select[name=activities]' ).multipleSelect( 'getSelects' ), function ( index, value ) {
			if ( value !== "" ) {
				var name = 'activities';

				if ( params[ name ] !== undefined ) {
					params[ name ] += ' ' + value;
				} else {
					params[ name ] = value;
				}
			}
		} );

		$.each( $( '.select[name=fonctions]' ).multipleSelect( 'getSelects' ), function ( index, value ) {
			if ( value !== "" ) {
				var name = 'fonctions';

				if ( params[ name ] !== undefined ) {
					params[ name ] += ' ' + value;
				} else {
					params[ name ] = value;
				}
			}
		} );

		$.each( $( '.select[name=missions]' ).multipleSelect( 'getSelects' ), function ( index, value ) {
			if ( value !== "" ) {
				var name = 'missions';

				if ( params[ name ] !== undefined ) {
					params[ name ] += ' ' + value;
				} else {
					params[ name ] = value;
				}
			}
		} );

		$.each( $( '.select[name=structures]' ).multipleSelect( 'getSelects' ), function ( index, value ) {
			if ( value !== "" ) {
				var name = 'structures';

				if ( params[ name ] !== undefined ) {
					params[ name ] += ' ' + value;
				} else {
					params[ name ] = value;
				}
			}
		} );

		that._setMinYear( params[ 'date-start' ], params[ 'date-end' ] );

		if ( $( '.js-filters-search' ).val() ) {
			params[ 'search' ] = $( '.js-filters-search' ).val();
		}

		var count = 0;
		var i;

		for ( i in params ) {
			if ( params.hasOwnProperty( i ) ) {
				count++;
			}
		}

		if ( count === 0 ) {
			this.removeSelection.addClass( 'remove-selection--is-hide' );
		} else {
			this.removeSelection.removeClass( 'remove-selection--is-hide' );
		}

		var url = this._changeURL( $.param( params, true ) );

		console.log( url );

		this._getData( url );

	},

	_loadCountry: function ( continent ) {
		var that = this;

		$.each( locations, function ( key, countries ) {
			if ( key === continent ) {
				that.filtersLocation.find( 'option' ).remove();

				$.each( countries, function ( slug, name ) {
					that.filtersLocation.append( '<option value="' + slug + '" selected>' + name + '</option>' );
				} );

				that.filtersLocation.multipleSelect( 'refresh' );
				that.filtersContinent.prev().fadeOut().addClass( 'select--is-hide' );
				that.filtersLocation.next().fadeIn().removeClass( 'select--is-hide' );
			}
		} );
	},

	_setSelectData: function ( data, refresh ) {
		var that = this;

		$( 'select.select--filters--fonctions' ).find( 'option, optgroup' ).remove();
		$( 'select.select--filters--missions' ).find( 'option, optgroup' ).remove();
		$( 'select.select--filters--structures' ).find( 'option, optgroup' ).remove();
		$( 'select.select--filters--fonctions' ).multipleSelect( 'refresh' );
		$( 'select.select--filters--missions' ).multipleSelect( 'refresh' );
		$( 'select.select--filters--structures' ).multipleSelect( 'refresh' );

		if ( data.length > 0 ) {

			$.each( filters, function ( filter ) {
				var name = filter;

				var check = [];

				$.each( filters[ name ], function ( key, value ) {

					if ( $.inArray( key, data ) !== -1 ) {
						var options = "";

						$.each( value, function ( key, elm ) {
							if ( $.inArray( elm.name, check ) === -1 ) {
								if ( elm.child !== undefined && elm.child.length > 0 ) {
									options += '<optgroup label="' + elm.name + '" class="optgroup ' + elm.slug + '">';
									$.each( elm.child, function ( key, child ) {
										options += '<option class="subfilter" value="' + child.slug + '" data-group="' + elm.slug + '">' + child.name + '</option>';
									} );
									options += '</optgroup>';
								} else {
									options += '<option value="' + elm.slug + '">' + elm.name + '</option>';
								}
							}

							check.push( elm.name );
						} );

						$( 'select.select--filters--' + name ).append( options );
					}

				} );

				$( 'select.select--filters--' + name ).multipleSelect( 'refresh' );

				if ( $( 'select.select--filters--' + name ).find( 'option' ).length > 0 ) {
					$( 'select.select--filters--' + name ).multipleSelect( 'enable' );
				} else {
					$( 'select.select--filters--' + name ).multipleSelect( 'disable' );
				}
			} );

		} else {
			$( 'select.select--filters--fonctions' ).multipleSelect( 'disable' );
			$( 'select.select--filters--missions' ).multipleSelect( 'disable' );
			$( 'select.select--filters--structures' ).multipleSelect( 'disable' );
		}

		that.container = $( ".form--filters" );
		if ( refresh ) {
			that.removeAllSection = false;
			that.container.submit();
		}
	},

	_getParams: function ( param ) {
		var vars = {};
		window.location.href.replace( location.hash, '' ).replace( /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
			function ( m, key, value ) { // callback
				vars[ key ] = value !== undefined ?
					value :
					'';
			} );

		if ( param ) {
			return vars[ param ] ?
				vars[ param ] :
				null;
		}
		return vars;
	}
} );
