var Transitions,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

Transitions = (function() {
  Transitions.START = 'callstart';

  Transitions.END = 'callend';

  Transitions.MIDDLE = 'callmiddle';

  function Transitions() {
    this._defaultIn = __bind(this._defaultIn, this);
    this._defaultOut = __bind(this._defaultOut, this);
    this._transitionIn = __bind(this._transitionIn, this);
    this._transitionOut = __bind(this._transitionOut, this);
    this._onRouterEnd = __bind(this._onRouterEnd, this);
    this._onRouterStart = __bind(this._onRouterStart, this);
    this._onRouterClick = __bind(this._onRouterClick, this);
    this._initEvents = __bind(this._initEvents, this);
    this.router = new Router();
    this._transitionInDelay = 0;
    this.transitionsWhenCallFinished = true;
    this._initEvents();
  }

  Transitions.prototype._initEvents = function() {
    return $(this.router).on(Router.CLICK, this._onRouterClick).on(Router.CALLSTART, this._onRouterStart).on(Router.CALLEND, this._onRouterEnd);
  };

  Transitions.prototype._onRouterClick = function() {};

  Transitions.prototype._onRouterStart = function() {
    if (this.transitionsWhenCallFinished !== true) {
      return this._transitionOut();
    }
  };

  Transitions.prototype._onRouterEnd = function() {
    var transitionName;
    if (this.transitionsWhenCallFinished === true) {
      transitionName = '_' + this.router.pages.prev + 'To' + this.router.pages.current.charAt(0).toUpperCase() + this.router.pages.current.slice(1);
      if (this[transitionName]) {
        return this[transitionName]();
      } else {
        this._transitionOut();
        return setTimeout((function(_this) {
          return function() {
            return _this._transitionIn();
          };
        })(this), this._transitionInDelay);
      }
    } else {
      return this._transitionIn();
    }
  };

  Transitions.prototype._transitionOut = function() {
    var transitionName;
    transitionName = '_' + this.router.pages.prev + 'Out';
    if (this[transitionName]) {
      return this[transitionName]();
    } else {
      return this._defaultOut();
    }
  };

  Transitions.prototype._transitionIn = function() {
    var transitionName;
    transitionName = '_' + this.router.pages.current + 'In';
    $(window).scrollTop(0);
    if (this[transitionName]) {
      return this[transitionName]();
    } else {
      return this._defaultIn();
    }
  };

  Transitions.prototype._defaultOut = function () {
    this.container = $('.content');
    this.router.requestInProgress = true;
    this.container.addClass('removed');
    this.container[0].offsetHeight;

    $('#container').removeClass('loaded');
    $('#container').addClass('loading');

    return $(this).trigger(Transitions.START);
  };

  Transitions.prototype._defaultIn = function() {
    var newContainer, oldContainer;
    oldContainer = $('.content');
    newContainer = this.router.content;
    oldContainer.eq(0).after(newContainer);
    oldContainer.remove();
    newContainer.addClass('added');
    newContainer[0].offsetHeight;
    newContainer.removeClass('added');
    this.sectionId = this.router.pages.current;
    $(this).trigger(Transitions.MIDDLE);
    this.router.requestInProgress = false;

    $('#container').removeClass('loading');
    $('#container').addClass('loaded');

    return $(this).trigger(Transitions.END);
  };

  return Transitions;

})();
