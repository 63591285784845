var Actuality = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#actuality' );
		this.listing = this.container.find( '.listing-news' );

		new Subnav();
		new FiltersNews();
		new LoadMore();
		new LoaderHeading();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );
