var Home = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#home' );
		this.listingNews = this.container.find( '.listing-news' );
		this.listingProjects = this.container.find( '.listing-projects' );
		this.callOnce = true;

		new Slider();
		new Animate();
		new Parallax();
		new Block();
		new LoaderHeading();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;
		
		if($('#logo_overlay .video video').length) {			
			that.vid = $('#logo_overlay .video video')[0];	
			function monitorVideo() {
// 				console.log('IN');
				if (((that.vid).duration - (that.vid).currentTime) < 0.9) {
			        if (that.callOnce) {
// 				        console.log('Kill');
			            $('#logo_overlay .video video').fadeOut();
						that._openCurtains();
			            that.callOnce = false;
                        clearInterval(interval); // stop the interval
			        }
		        }
			}	
			interval = setInterval(monitorVideo, 100);
			
/*
			$('#logo_overlay .video video').on('ended', function(){
// 				$('#logo_overlay .video').addClass('remove_it');
// 		    	setTimeout(function() {
// 			    	that._openCurtains();
// 			    }, 100);
				$('#logo_overlay .video video').fadeOut();
				that._openCurtains();
			});
*/
		}
	},
	
	_openCurtains: function () {
		var that = this;
		
		if($('#logo_overlay').length) {
		    $('#logo_overlay').addClass('open');
		    
		    setTimeout(function() { 
		        $('#logo_overlay').remove();
		    }, 600);
		}
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );
