var Innovation = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#innovation' );

		new Parallax();
		new LoaderHeading();
		new Animate();

		this._initEvents();

		setTimeout( function () {
			that.resize();
		}, 150 );
	},

	_initEvents: function () {
		var that = this;
		var temp = 0;

		this.container.find( '.block__slider' ).each( function () {
			var elm = $( this );
			setTimeout( function () {
				new MultiSlider( elm );
			}, temp );

			temp += temp + 2000;
		} );
	},

	resize: function () {
		var that = this;
		this.container.find( '.block' ).css( 'min-height', this.container.find( '.block__slider' ).eq( 0 ).height() );
	},

	destroy: function () {

	}

} );
