var NinjaFormAjax = Class.extend({

      init: function () {
      	this.container = $('body');
      	this.$productSingle = $(".product-single");
      	this.$singleWrapperProduct = this.$productSingle.find(".product-single__wrapper-product");
        	this.$singleWrapperForm = this.$productSingle.find(".product-single__wrapper-form");
      	this.ninja = this.container.find('.ninja-forms-form');
      	this.errorsContent = this.container.find('.ninja-forms-field-error');
      	this.errors;

      	this.textSendBtn;
      	this.textProcessBtn;

      	this._initEvents();
    	},

    	_initEvents: function () {
        var that = this;

        	this.ninja.find('form').show();

        	this.ninja.each(function(index){
        		var id = $(this).find('#_form_id').val();
        		$(this).find('#nf_submit_' + id).find('input').attr('data-id', id);
		});

        	this.ninja.find('input[type=submit]').on(Event.CLICK, function(event){
        		event.stopPropagation();
        		event.preventDefault();


        		// clean all msg
    			$('.ninja-forms-response-msg').html();
    			$('.ninja-forms-response-msg').removeClass('ninja-forms-error-msg, ninja-forms-success-msg');
    			that.errorsContent.hide();

        		var submitID = $(this).attr('data-id');

        		that.ninja.each(function(index){
        			var id = $(this).find('#_form_id').val();

        			if(id === submitID){
        				var data = $(this).serialize();
        				that._ajaxRequest(data, $(this));
        			}
			});

        	});

    	},

    	_ajaxRequest:function(data, form){
    		var that = this;

    		this.textSendBtn = form.find('input[type=submit]').eq(0).val();
    		this.textProcessBtn = form.find('input[type=submit]').eq(1).val();

    		form.find('input[type=submit]').eq(0).val(this.textProcessBtn);

    		$.ajax({
            	url: form.attr('action'),
            	type: 'post',
            	data: data,
            	dataType: 'json',
            	success: function (data) {
                		var formID = data.form_id;
                		var errors = data.errors;

                		if(!data.success){
                			$.each(data.errors, function(key,val) {
						if(key === 'required-general'){
							$('#ninja_forms_form_' + formID + '_response_msg').addClass('ninja-forms-error-msg');
							$('#ninja_forms_form_' + formID + '_response_msg').html('<p>' + data.errors[key].msg + '</p>');
						} else {
							var id = data.errors[key].location;
							$('#ninja_forms_field_' + id + '_error').show();
							$('#ninja_forms_field_' + id + '_error').html(data.errors[key].msg);
						}
  					});
                		} else {

                			if(that.container.hasClass('page-template-contact')){
                				form.hide();
                			} else {
                				// close popup
                				setTimeout(function(){
                					that.$singleWrapperProduct.addClass("is-active");
            					that.$singleWrapperForm.removeClass("is-active");
                				 }, 3000);
                			}

                			if(data.success['success_msg-Success message'] != undefined){
                				var msg = data.success['success_msg-Success message'];
                			} else {
                				var msg = data.success['success_msg-Success Message'];
                			}

                			$('#ninja_forms_form_' + formID + '_response_msg').addClass('ninja-forms-success-msg');
                			$('#ninja_forms_form_' + formID + '_response_msg').html(msg);
                		}

                		form.find('input[type=submit]').eq(0).val(that.textSendBtn);

            	},
        	});
    	}

});
