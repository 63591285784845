var ChartNumbers = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".chart" );
		this.canvas = this.container.find( '#chart__number' );
		this.tooltips = this.container.find( '.chart__tooltips' );
		this.url = this.canvas.attr( 'data-url' );
		this.data = [];
		this.scrollTop = 0;

		this.isData = false;
		this.isGenerated = false;

		this._initEvents();
		this._getData();
	},

	_initEvents: function () {
		var that = this;

		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_getData: function () {
		var that = this;
		$.getJSON( this.url, function ( data ) {
			that.isData = true;
			that.data = data;
		} );
	},

	_onMouseWheel: function () {
		var that = this;
		this.scrollTop = $( window ).scrollTop();

		if ( this.scrollTop > that.container.offset().top - ( W.wh / 1.2 ) && that.isData && !this.isGenerated ) {
			this._generateChart();
			this.isGenerated = true;
		}
	},

	_generateChart: function () {
		var that = this;

		data = this.data;

		var ctx = document.getElementById( "chart__number" );
		var myChart = new Chart( ctx, {
			type: 'bar',
			data: {
				labels: data.labels,
				datasets: [ {
					label: data.legend_1,
					data: data.data,
					people: data.people,
					backgroundColor: data.bg,
		        } ]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				scaleLabel: function ( label ) {
					return label.value.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," ) + ' Mio €';
				},
				hover: {
					onHover: function ( event ) {
						//event.preventDefault();
						//event.stopPropagation();
					}
				},
				legend: {
					//display: false
					onClick: function ( event, legendItem ) {
						event.preventDefault();
						event.stopPropagation();
					}
				},
				animation: {
					duration: 200,
					onComplete: function ( animation ) {
						var ctx = this.chart.ctx;

						if ( device === "mobile" ) {
							ctx.font = '11px "greisch", "Helvetica", Arial';
						} else {
							ctx.font = '17px "greisch", "Helvetica", Arial';
						}

						ctx.fillStyle = 'rgba(0,0,0, 1)';
						ctx.textAlign = "center";
						ctx.textBaseline = "bottom";

						this.data.datasets.forEach( function ( dataset ) {
							for ( var i = 0; i < dataset.data.length; i++ ) {

								var model = dataset._meta[ Object.keys( dataset._meta )[ 0 ] ].data[ i ]._model;
								ctx.fillText( dataset.people[ i ], model.x, model.y + 48 );
								ctx.fillText( '\uE017', model.x, model.y + 30 );
							}
						} );
					}
				},
				tooltips: {
					enabled: false
				},
				scales: {
					yAxes: [ {
						ticks: {
							beginAtZero: true,
							fixedStepSize: 5,
							fontStyle: 'Bold',
							fontColor: 'rgba(220, 100, 22, 1)',
							callback: function ( label, index, labels ) {
								return label + ' Mio €';
							}
						},
						scaleLabel: {
							display: true
						}
            		} ],
					xAxes: [ {
						ticks: {
							displayFormats: 'year',
						},
            		} ]
				}
			}
		} );
	},
} );;
