var TransitionsFactory;

TransitionsFactory = (function(_super) {
  __extends(TransitionsFactory, _super);

  function TransitionsFactory() {
    this._transitionOut = __bind(this._transitionOut, this);
    TransitionsFactory.__super__.constructor.apply(this, arguments);
    this._transitionInDelay = 2000;
  }

  TransitionsFactory.prototype._transitionOut = function() {
    //W.body.css('background-color', $('.ajax-container').css('background-color'));

    return TransitionsFactory.__super__._transitionOut.apply(this, arguments);
  };

  TransitionsFactory.prototype._defaultIn = function() {

    var section = $('.content').attr('id');

    return TransitionsFactory.__super__._defaultIn.apply(this, arguments);
  };

  return TransitionsFactory;

})(Transitions);
