var gMaps = Class.extend( {

	init: function () {
		this.container = $( '.content' );
		this.gMaps = this.container.find( '#gmaps' );
		this.json = this.container.data( 'json' );
		this.read = this.container.attr( 'data-read' );
		this.isMapReady = false;
		this.map;
		this.oMarker;
		this.markers = [];

		this._initEvents();
		this._loadMap();
	},

	_initEvents: function () {

		var that = this;

		$( window ).bind( 'gMapsLoaded', function () {
			that._initializeMap( that );
		} );
	},

	_loadMap: function () {

		var that = this;

		// if google maps is loaded => Callback.
		if ( gMapsLoaded ) return window.gMapsCallback();

		// else add script google maps in head.
		var script_tag = document.createElement( 'script' );
		script_tag.setAttribute( "type", "text/javascript" );
		script_tag.setAttribute( "src", location.protocol + "//maps.google.com/maps/api/js?key=AIzaSyCxWfkDkcVB3pJX8TueDt-3HH_w7A05u1U&callback=gMapsCallback" );
		( document.getElementsByTagName( "head" )[ 0 ] || document.documentElement ).appendChild( script_tag );

	},

	_initializeMap: function ( that ) {
		var that = this;

		// if google maps reday, set height on this.
		if ( that.isMapReady === false ) {

			that._createMap();
		}

		/*   this.defaultCenter = this.map.getCenter();
		 that.map.setZoom(that.zoom);
		 */
	},

	_createMap: function () {

		var that = this;

		// OPTIONS
		var mapOptions = {
			center: new google.maps.LatLng( 50.850340, 4.351710 ),
			zoom: 8,
			scrollwheel: false,
			panControl: false,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: false,
			minZoom: 1,
			maxZoom: 14
		};


		// STYLES
		var styles = [ {
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [ {
				"color": "#d3d3d3"
			} ]
        }, {
			"featureType": "transit",
			"stylers": [ {
				"color": "#808080"
			}, {
				"visibility": "off"
			} ]
        }, {
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [ {
				"visibility": "on"
			}, {
				"color": "#b3b3b3"
			} ]
        }, {
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [ {
				"color": "#ffffff"
			} ]
        }, {
			"featureType": "road.local",
			"elementType": "geometry.fill",
			"stylers": [ {
				"visibility": "on"
			}, {
				"color": "#ffffff"
			}, {
				"weight": 1.8
			} ]
        }, {
			"featureType": "road.local",
			"elementType": "geometry.stroke",
			"stylers": [ {
				"color": "#d7d7d7"
			} ]
        }, {
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [ {
				"visibility": "on"
			}, {
				"color": "#ebebeb"
			} ]
        }, {
			"featureType": "administrative",
			"elementType": "geometry",
			"stylers": [ {
				"color": "#a7a7a7"
			} ]
        }, {
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [ {
				"color": "#ffffff"
			} ]
        }, {
			"featureType": "road.arterial",
			"elementType": "geometry.fill",
			"stylers": [ {
				"color": "#ffffff"
			} ]
        }, {
			"featureType": "landscape",
			"elementType": "geometry.fill",
			"stylers": [ {
				"visibility": "on"
			}, {
				"color": "#efefef"
			} ]
        }, {
			"featureType": "road",
			"elementType": "labels.text.fill",
			"stylers": [ {
				"color": "#696969"
			} ]
        }, {
			"featureType": "administrative",
			"elementType": "labels.text.fill",
			"stylers": [ {
				"visibility": "on"
			}, {
				"color": "#737373"
			} ]
        }, {
			"featureType": "poi",
			"elementType": "labels.icon",
			"stylers": [ {
				"visibility": "off"
			} ]
        }, {
			"featureType": "poi",
			"elementType": "labels",
			"stylers": [ {
				"visibility": "off"
			} ]
        }, {
			"featureType": "road.arterial",
			"elementType": "geometry.stroke",
			"stylers": [ {
				"color": "#d6d6d6"
			} ]
        }, {
			"featureType": "road",
			"elementType": "labels.icon",
			"stylers": [ {
				"visibility": "off"
			} ]
        }, {}, {
			"featureType": "poi",
			"elementType": "geometry.fill",
			"stylers": [ {
				"color": "#dadada"
			} ]
		} ];


		//CREATE MAP

		that.map = new google.maps.Map( document.getElementById( 'map-canvas' ), mapOptions );

		//SET STYLES
		var styledMapType = new google.maps.StyledMapType( styles, {
			name: 'Styled'
		} );

		that.map.mapTypes.set( 'Styled', styledMapType );
		that.map.setMapTypeId( 'Styled' );

		that.makeMapOverlayView();

		that.isMapReady = true;
		if ( that.json !== undefined ) {
			$.getScript( location.protocol + "//greisch.com/wp-content/themes/greisch/library/js/infobox_packed.js" ).done( function ( script, textStatus ) {
				that._loadMarkers( that.json );
			} );
		} else if ( that.gMaps.attr( 'data-lat' ) !== "" && that.gMaps.attr( 'data-lng' ) !== "" && that.gMaps.attr( 'data-lat' ) !== undefined && that.gMaps.attr( 'data-lng' ) !== undefined ) {
			that._loadMarker( that.gMaps.attr( 'data-lat' ), that.gMaps.attr( 'data-lng' ) );
		}

	},

	_loadMarker: function ( lat, lng ) {
		var that = this;

		var place = that.gMaps.attr( 'data-address' );
		var markerLatLng = new google.maps.LatLng( lat, lng );
		var link = "http://maps.google.com/maps/place/" + place + "/@" + lat + "," + lng + ",15z";

		that.oMarker = new google.maps.Marker( {
			map: that.map,
			position: markerLatLng,
			icon: icon_marker,
			link: link,
		} );

		that.map.panTo( that.oMarker.getPosition() );

		google.maps.event.addListener( that.oMarker, 'click', function () {
			that.map.panTo( that.oMarker.getPosition() );
			that.map.setCenter( that.oMarker.getPosition() );
			window.open( that.oMarker.link, '_blank' );
		} );
	},

	_loadMarkers: function ( url ) {
		var that = this;

		$.getJSON( url, function ( data ) {

			if ( data.length !== 0 ) {

				$.each( data, function ( i, marker ) {
					if ( marker.lat !== "" && marker.lng !== "" || marker.lat != 0 && marker.lng !== 0 ) {
						var markerLatLng = new google.maps.LatLng( marker.lat, marker.lng );

						that.oMarker = new google.maps.Marker( {
							id: marker.id,
							map: that.map,
							name: marker.title,
							address: marker.address,
							position: markerLatLng,
							icon: marker.icon,
							link: marker.link,
							img: marker.thumb.url,
							alt: marker.thumb.alt
						} );

						google.maps.event.addListener( that.oMarker, 'mouseover', function ( e ) {
							that.showMapTooltip( this );
						} );

						google.maps.event.addListener( that.oMarker, 'mouseout', function ( e ) {
							that.hideMapTooltip();
						} );

						// push oMarker to array.
						that.markers.push( that.oMarker );

						// create tooltip.
						var boxText = document.createElement( "div" );
						var text = "";

						text += '<div class="infobox__inner">';

						text += '<h4 class="title-h4 infobox__inner__title">' + marker.title + '</h4>';

						if ( marker.address ) {
							text += '<p class="infobox__inner__content">' + marker.address + '</p>';
						}

						if ( marker.thumb.url !== "" ) {
							text += '<a href="' + marker.link + '"><img class="infobox__inner__thumb" src="' + marker.thumb.url + '" alt="' + marker.thumb.url + '"></a>';
						}

						text += '<a href="' + marker.link + '" class="read-more read-more--link infobox__inner__link">' + that.read + '<i class="icon icon-arrow--right"></i></a>';

						text += '</div>';

						boxText.innerHTML = text;

						var myOptions = {
							content: boxText,
							pixelOffset: new google.maps.Size( -35, -190 ), // WIDTH / 2
							closeBoxURL: location.protocol + "//greisch.com/wp-content/themes/greisch/library/img/icon-close.png",
							boxClass: "infobox"
						};


						google.maps.event.addListener( that.oMarker, "click", function ( e ) {
							that.map.setCenter( this.internalPosition );
							$( '.infobox' ).hide();
							ib.open( that.map, this );
						} );


						var ib = new InfoBox( myOptions );
					}

				} );

				// var newCenter = new google.maps.LatLng(data.map.lat, data.map.lng);
				// that.map.setCenter(newCenter);

				//  create a new viewpoint bound
				var bounds = new google.maps.LatLngBounds();

				for ( var i = 0; i < that.markers.length; i++ ) {
					// and increase the bounds to take this point

					if ( that.markers[ i ].position.lat() && that.markers[ i ].position.lng() && that.markers[ i ].position.lat() !== null && that.markers[ i ].position.lat() !== 0 && that.markers[ i ].position.lng() !== null && that.markers[ i ].position.lng() !== 0 ) {
						if ( !isNaN( that.markers[ i ].position.lat() ) && !isNaN( that.markers[ i ].position.lng() ) ) {
							bounds.extend( new google.maps.LatLng( that.markers[ i ].position.lat(), that.markers[ i ].position.lng() ) );
						}
					}
				}

				that.map.fitBounds( bounds );

			}

		} );
	},

	makeMapOverlayView: function () {
		this.mapOverlayView = new google.maps.OverlayView();
		this.mapOverlayView.draw = function () {};
		this.mapOverlayView.setMap( this.map );
	},

	getMarkerPixelPosition: function ( marker ) {
		var projection = this.mapOverlayView.getProjection();
		return projection.fromLatLngToContainerPixel( marker.getPosition() );
	},

	showMapTooltip: function ( marker ) {
		var pos = this.getMarkerPixelPosition( marker );
		var toolTipContent = '';
		if ( marker.title ) {
			toolTipContent += '<h4 class="map-tooltip__title">' + marker.title + '</h4>';
		}

		var mapTooltip = $( '.js-map-tooltip' );
		mapTooltip.css( {
			left: pos.x,
			top: pos.y
		} );

		if ( marker.title ) {
			mapTooltip.html( toolTipContent ).show();
		}

	},

	hideMapTooltip: function () {
		var mapTooltip = $( '.infobox' );
		//mapTooltip.hide();
	},

	_filtersMarker: function () {
		var that = this;

		// set markers null
		for ( var i = 0; i < that.markers.length; i++ ) {
			that.markers[ i ].setMap( null );
		};

		// delete element witch is an array.
		this.markers = [];
		this._loadFiltersMarker();
	},

	_loadFiltersMarker: function () {
		var that = this;
		var jsonMarkers = this.container.attr( 'data-json' );
		this._loadMarkers( jsonMarkers );

		return true;
	},

} );
