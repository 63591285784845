var Project = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#project' );
		this.tabs = this.container.find( '.medias__tabs__item' );
		this.navgation = this.container.find( '.medias__navigation' );
		this.navgationItem = this.navgation.find( '.medias__navigation__item' );
		this.section = this.container.find( '.medias__tab-section' );
		this.sections = this.container.find( '.medias__tab-sections' );
		this.sectionPages = this.section.find( '.medias__tab-section__pages' );
		this.sectionPage = this.section.find( '.medias__tab-section__page' );

		new Slider();

		this._initPagination();
		this._initEvents();
		that.resize();

		new Animate();
		new Parallax();
		new LoaderHeading();
		new Popup();

		new gMaps();
	},

	_initEvents: function () {
		var that = this;
		var sectionsHeight = 0;


		lightbox.option( {
			'resizeDuration': 300,
			'wrapAround': true,
			'albumLabel': translate.albumLabel
		} );

		this.section.eq( 0 ).addClass( 'medias__tab-section--current' );
		this.tabs.eq( 0 ).addClass( 'medias__tabs__item--current' );


		$( '.medias__tab-section--gallery .medias__tab-section__page' ).each( function () {
			$( this ).eq( 0 ).waterfall();
		} );

		$( '.medias__tab-section--video .medias__tab-section__page' ).each( function () {
			$( this ).eq( 0 ).waterfall();
		} );

		that.resize();

		setTimeout( function () {
			that.resize();
		}, 1000 );

		this.tabs.on( Event.CLICK, function () {
			that.tabs.removeClass( 'medias__tabs__item--current' );
			that.section.removeClass( 'medias__tab-section--current' );
			that.container.find( '[data-id=' + $( this ).attr( 'data-tab' ) + ']' ).addClass( 'medias__tab-section--current' );
			$( this ).addClass( 'medias__tabs__item--current' );

			that.resize();
		} );

		this.navgationItem.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var index = $( this ).attr( 'data-pageid' );
			var elm = $( this ).attr( 'data-type' );

			$( this ).parent().find( 'li' ).removeClass( 'medias__navigation__item--current' );
			$( this ).addClass( 'medias__navigation__item--current' );

			that._goToSlide( index, elm );
		} );

	},

	_initPagination: function () {
		this.navgation.each( function () {
			$( this ).find( '.medias__navigation__item' ).eq( 0 ).addClass( 'medias__navigation__item--current' );
		} );
	},

	_goToSlide: function ( i, elm ) {
		$( '.medias__tab-section--' + elm ).find( '.medias__tab-section__pages' ).css( 'left', -i * this.section.outerWidth() );
	},

	resize: function () {
		var that = this;
		var sectionsHeight = 0;

		this.sectionPage.css( 'width', this.section.outerWidth() );

		this.sectionPages.each( function () {
			$( this ).css( 'width', $( this ).find( '.medias__tab-section__page' ).length * that.section.outerWidth() );
		} );

		$( '.medias__tab-sections' ).css( 'height', $( '.medias__tab-section--current' ).outerHeight() );

	},

	destroy: function () {

	}

} );
