var LoaderHeading = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".heading" );
		this.url = this.container.find( '[data-img]' );

		if ( this.url.length > 0 ) {
			this._initEvents();
		}

	},

	_initEvents: function () {
		var that = this;
		$( '<img/>' ).attr( 'src', this.url.attr( 'data-img' ) ).load( function () {
			that.container.addClass( 'heading--is-loaded' );
		} );
	},
} );
