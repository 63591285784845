var Subnav = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".subnav" );
		this.link = this.container.find( '.subnav__menu__item__link' );
		this.offset = this.container.offset().top;
		this.sections = $( '[data-subnav]' );
		this.btn = this.container.find( '.btn--filters' );

		this._initEvents();
		this._onMouseWheel();
	},

	_initEvents: function () {
		var that = this;

		this.btn.on( Event.CLICK, function () {
			that.container.toggleClass( 'subnav--is-open' );

			if ( that.container.hasClass( 'subnav--is-open' ) ) {
				$( this ).text( $( this ).data( 'hide' ) );
			} else {
				$( this ).text( $( this ).data( 'show' ) );
			}
		} );

		this.link.on( Event.CLICK, function ( e ) {

			if ( !that.container.hasClass( 'subnav--filters' ) ) {

				e.preventDefault();
				e.stopPropagation();

				that.container.removeClass( 'subnav--is-open' );
				that.btn.text( $( this ).data( 'hide' ) );

				var hash = $( this ).attr( 'href' );
				var hashOffset = $( hash ).offset().top;
				var ddh = $( document ).height();

				if ( device !== "mobile" ) {
					that.container.removeClass( 'subnav--is-open' );
				}

				if ( hashOffset > ( ddh - W.wh ) ) {
					hashOffset = hashOffset - ( W.wh / 1.4 );
				}

				$( 'html,body' ).animate( {
					scrollTop: hashOffset - 50,
				}, 2000, 'easeInOutExpo' );

			}
		} );

		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_onMouseWheel: function () {
		var that = this;
		var scrollTop = $( window ).scrollTop();

		if ( scrollTop >= this.container.outerHeight() ) {
			this.container.addClass( 'subnav--is-fixed' );

			if ( $( 'header' ).hasClass( 'header--is-hide' ) ) {
				this.container.css( 'top', 0 );
			} else {
				this.container.css( 'top', $( 'header' ).outerHeight() );
			}

		} else {
			this.container.css( 'top', $( 'header' ).outerHeight() );
		}

		if ( device !== "mobile" ) {
			that.container.removeClass( 'subnav--is-open' );
		}

		this.sections.each( function ( index ) {
			var offsetTop = $( this ).offset().top - 100;
			var offsetEnd = $( this ).outerHeight() + offsetTop;

			if ( scrollTop >= offsetTop && scrollTop <= offsetEnd ) {
				that.link.eq( index ).addClass( 'subnav__menu__item__link--current' );
			} else {
				that.link.eq( index ).removeClass( 'subnav__menu__item__link--current' );
			}
		} );
	},
} );
