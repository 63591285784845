var Hash = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( "body" );
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.container.find( 'a[href^="#"]' ).on( Event.CLICK, function ( e ) {

			e.stopPropagation();
			e.preventDefault();

			var hash = $( this ).attr( 'href' );
			var hashOffset = $( hash ).offset().top;
			var ddh = $( document ).height();

			if ( hashOffset > ( ddh - W.wh ) ) {
				hashOffset = hashOffset - ( W.wh / 1.4 );
			}

			$( 'html,body' ).animate( {
				scrollTop: hashOffset
			}, 500 );

		} );
	},
} );
