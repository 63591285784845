var About = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#about' );
		this.timeline = this.container.find( '.timeline' );
		this.url = this.timeline.attr( 'data-url' );
		this.chart = this.container.find( '.chart' );

		new Animate();
		new Parallax();
		new LoaderHeading();
		new Block();
		new ChartNumbers();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;



		function alerter( s ) {
			return function () {
				alert( s );
			};
		}

		// https://github.com/NUKnightLab/TimelineJS3/blob/3.3.7/API.md#events
		// events that don't fire as expected:
		//   loaded, dataloaded
		var events = [
			"back_to_start",
			"change",
			"dataloaded",
			"loaded",
			"zoom_in",
			"zoom_out",
			"nav_next",
			"nav_previous"
		];

		timeline = new TL.Timeline(
			'timeline', that.url, {
				'lang': lang,
				'start_at_slide': start_date,
				'scale_factor': 10,
				'timenav_height_min': 365, // Minimum timenav height
				'marker_height_min': 55, // Minimum Marker Height
				'marker_width_min': 300, // Minimum Marker Width
				'start_at_end': true,

			}
		);

		events.forEach( function ( evt ) {
			timeline.on( evt, function () {} );
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}




} );