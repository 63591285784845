var Router,
	__bind = function ( fn, me ) {
		return function () {
			return fn.apply( me, arguments );
		};
	};

Router = ( function () {
	Router.CALLSTART = 'callstart';

	Router.CALLEND = 'callend';

	Router.CALLERROR = 'callerror';

	Router.INITIALIZE = 'initialize';

	Router.CLICK = 'click';

	Router.singleton;

	function Router() {
		this.backCall = __bind( this.backCall, this );
		this.cache = {};
		this.container = $( '.content' );
		this.current = this.container.attr( 'id' );
		this.headTitle = $( 'title' );
		this.pages = {
			'prev': '',
			'current': this.container.attr( 'id' )
		};
		this.requestInProgress = false;
		this.fromCache = false;
		this.fromNativeNav = false;
		this._initEvents();
		this.initCache();
		Router.singleton = this;
	}

	Router.prototype.initCache = function () {
		this.href = document.location.pathname;
		this.content = this.container;
		return this.caching();
	};

	Router.prototype._initEvents = function () {
		$( document ).on( 'click', 'a', ( function ( _this ) {
			return function ( e ) {
				_this.elm = $( e.currentTarget );
				_this.href = _this.elm.attr( 'href' );
				_this.checkRequestAvailability();

				if ( _this.isRequestAvailable ) {
					_this.getContent();
				}
				if ( !_this.isTargetSet ) {
					e.preventDefault();
				}
				return $( _this ).trigger( Router.CLICK );
			};
		} )( this ) );

		return $( window ).on( 'popstate', ( function ( _this ) {
			return function ( event ) {
				if ( document.location.pathname.split( '#' )[ 0 ] !== _this.href ) {
					_this.backCall();
					return event.preventDefault();
				}
			};
		} )( this ) );
	};

	Router.prototype.checkRequestAvailability = function () {
		this.isRequestAvailable = true;
		this.isTargetSet = false;
		if ( this.areUrlsMatching() ) {
			this.isRequestAvailable = false;
		}
		if ( this.requestInProgress ) {
			this.isRequestAvailable = false;
		}
		if ( this.elm.attr( 'target' ) ) {
			this.isTargetSet = true;
			return this.isRequestAvailable = false;
		}
	};

	Router.prototype.areUrlsMatching = function () {
		var currentPath, currentUrl, urlToCheck;
		urlToCheck = this.href;
		currentPath = document.location.pathname;
		currentUrl = document.location.href;

		if ( urlToCheck.substr( -1 ) === '/' ) {
			urlToCheck = urlToCheck.substr( 0, urlToCheck.length - 1 );
		}
		if ( currentUrl.substr( -1 ) === '/' ) {
			currentUrl = currentUrl.substr( 0, currentUrl.length - 1 );
			currentPath = currentPath.substr( 0, currentPath.length - 1 );
		}
		if ( urlToCheck === currentPath || urlToCheck === currentUrl ) {
			return true;
		}
		return false;
	};

	Router.prototype.backCall = function () {
		this.fromNativeNav = true;
		if ( document.location.pathname === this.href ) {
			return window.history.go( -1 );
		} else {
			this.href = document.location.pathname;
			return this.getContent();
		}
	};

	Router.prototype.getContent = function () {
		this.pages.prev = this.pages.current;
		this.requestInProgress = true;
		$( this ).trigger( Router.CALLSTART );

		if ( this.cache[ this.href ] ) {

			this.fromCache = true;

			this.content = this.cache[ this.href ][ 'content' ].clone();
			this.title = this.cache[ this.href ][ 'title' ];

			console.log( this.cache[ this.href ][ 'class' ] );

			$( 'body' ).attr( 'class', this.cache[ this.href ][ 'class' ] );

			$( ".menu" ).find( "li" ).removeClass( "current-menu-item" );
			$( ".menu" ).find( "li" ).removeClass( "current_page_item" );

			$.each( this.cache[ this.href ][ 'menu' ].find( "li" ), function ( i, elm ) {


				if ( $( elm ).hasClass( "current-menu-item" ) ) {

					$( ".menu" ).find( "li" ).eq( i ).addClass( "current-menu-item" );
				}

				if ( $( elm ).hasClass( "current_page_item" ) ) {

					$( ".menu" ).find( "li" ).eq( i ).addClass( "current_page_item" );
				}

			} );

			return this.requestSucceeded();

		} else {
			this.fromCache = false;
			return this.request();
		}
	};

	Router.prototype.request = function () {
		if ( this.ajaxRequest && this.ajaxRequest !== 4 ) {
			this.ajaxRequest.abort();
		}
		return this.ajaxRequest = $.ajax( {
			url: this.href,
			success: ( function ( _this ) {
				return function ( response ) {
					_this.ajaxResponse = response;
					_this.content = $( response ).filter( '.content' );
					_this.menu = $( response ).find( '.menu' );
					_this.active = _this.menu.find( ".current-menu-item, .current_page_item" );
					_this.iFlag = 0;


					$( ".menu" ).find( "li" ).removeClass( "current-menu-item" );
					$( ".menu" ).find( "li" ).removeClass( "current_page_item" );

					$.each( _this.menu.find( "li" ), function ( i, elm ) {


						if ( $( elm ).hasClass( "current-menu-item" ) ) {

							$( ".menu" ).find( "li" ).eq( i ).addClass( "current-menu-item" );
						}

						if ( $( elm ).hasClass( "current_page_item" ) ) {

							$( ".menu" ).find( "li" ).eq( i ).addClass( "current_page_item" );
						}

					} );

					var $dom = $( document.createElement( "html" ) );
					$dom[ 0 ].innerHTML = response;
					_this.body = $dom.find( 'body' ).attr( 'class' );

					$( 'body' ).attr( 'class', _this.body );

					if ( _this.content.length === 0 ) {
						_this.content = $( response ).find( '.content' );
					}
					_this.title = $( response ).filter( 'title' ).text();
					return _this.requestSucceeded();
				};
			} )( this ),
			complete: ( function ( _this ) {
				return function ( request, status ) {};
			} )( this ),
			error: ( function ( _this ) {
				return function ( response ) {
					return $( _this ).trigger( Router.CALLERROR );
				};
			} )( this )
		} );
	};

	Router.prototype.requestSucceeded = function ( response ) {
		this.pages.current = this.content.attr( 'id' );
		this.changeTitle();
		this.caching();

		if ( this.fromNativeNav === false ) {
			this.changeUrl();
		}
		this.fromNativeNav = false;
		return $( this ).trigger( Router.CALLEND );
	};

	Router.prototype.changeTitle = function () {
		return this.headTitle.text( this.title );
	};

	Router.prototype.caching = function () {
		var cache = {};
		cache[ 'href' ] = this.href;
		cache[ 'content' ] = this.content.clone();
		cache[ 'header' ] = $( 'body' ).find( 'header .primary-navigation' ).clone();
		cache[ 'footer' ] = $( 'body' ).find( '#menu-footer-nav' ).clone();
		cache[ 'topnav' ] = $( 'body' ).find( '#menu-top-nav' ).clone();
		cache[ 'class' ] = $( 'body' ).attr( 'class' );
		cache[ 'id' ] = this.container.attr( 'id' );
		cache[ 'title' ] = document.title;
		cache[ 'menu' ] = $( 'body' ).find( '.menu' ).clone();

		return this.cache[ this.href ] = cache;
	};

	Router.prototype.changeUrl = function ( href ) {
		var pathname, state;
		if ( href ) {
			this.href = href;
		}
		state = {};
		pathname = this.href.split( window.location.host )[ 1 ];
		if ( pathname ) {
			pathname = pathname.substr( 4 );
		}
		if ( window.history.pushState ) {
			if ( this.pages.current === this.pages.prev ) {
				return window.history.replaceState( state, null, this.href );
			} else {
				return window.history.pushState( state, null, this.href );
			}
		} else {
			return window.location.hash = pathname;
		}
	};

	return Router;

} )();
