var Popup = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( 'body' );
		this.popup = this.container.find( '.popup' );
		this.cache = [];
		this.link = this.container.find( '.listing-team a, .listing-block__item__link' );
		this.btn = this.container.find( '.heading__button' );
		this.btnVideo = this.container.find( '.btn--video' );


		that.isLoad = false;
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.link.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var url = $( this ).attr( 'href' );
			var params = {
				'member': $( this ).attr( 'data-slug' )
			};

			if ( !that.isLoad ) {
				that.isLoad = true;
				that._loadData( url, params );
			}

		} );

		$( '.btn--apply' ).on( Event.CLICK, function ( e ) {
			var type = $( this ).attr( 'data-type' );
			var object = $( this ).attr( 'data-apply' );
			var id = $( this ).attr( 'data-id' );
			var email = $( this ).attr( 'data-email' );

			$( '.form__tab' ).hide();

			$( '.form__tab' ).each( function () {
				if ( $( this ).attr( 'data-tab' ) === type ) {
					$( this ).show();
					$( this ).find( '.object' ).val( object );
					$( this ).find( '.object' ).attr( 'value', object );
					$( this ).find( '.object' ).trigger( "change" );
					$( this ).find( '.identification' ).val( id );
					$( this ).find( '.identification' ).attr( 'value', id );
					$( this ).find( '.identification' ).trigger( "change" );
					$( this ).find( '.admin-email' ).val( email );
					$( this ).find( '.admin-email' ).attr( 'value', email );
					$( this ).find( '.admin-email' ).trigger( "change" );
					that.popup.addClass( 'popup--is-open' );
					that.popup.find( '.form__title' ).text( object );
				}
			} );
		} );

		this.btn.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();
			var url = $( this ).attr( 'href' ).split( '/' );
// 			var fullUrl = $( this ).attr( 'href' );
// 			var id = $( this ).attr( 'data-uri' );
// 			var html = $( this ).attr( 'data-html' );

			if ( !that.isLoad ) {
				that.isLoad = true;
				that._setVimeoPlayer( url );
			}
		} );

		this.btnVideo.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var url = $( this ).attr( 'href' ).split( '/' );
// 			var fullUrl = $( this ).attr( 'href' );
// 			var id = $( this ).attr( 'data-uri' );
// 			var html = $( this ).attr( 'data-html' );

			if ( !that.isLoad ) {
				that.isLoad = true;
				that._setVimeoPlayer( url );
			}
		} );

		$( document ).keyup( function ( e ) {
			if ( e.keyCode == 27 ) { // escape key maps to keycode `27`
				that._closePopup();
			}
		} );

		this.container.find( '.popup__cache' ).on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();
			that._closePopup();
		} );

		if ( this._getParams( 'member' ) ) {
			this.link.each( function () {
				if ( $( this ).attr( 'data-slug' ) === that._getParams( 'member' ) ) {
					var url = $( this ).attr( 'href' );
					var params = {
						'member': $( this ).attr( 'data-slug' )
					};

					that._loadData( url, params );
				}
			} );
		}

	},

	_setVimeoPlayer: function ( url ) {
		var vimeo = '<div class="popup__inner__video"><iframe src="https://player.vimeo.com/video/' + url[3] + '?h=' + url[4] + '" width="1920" height="1080" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe></div>';
// 		var vimeo = '<div class="popup__inner__video">' + html + '</div>';

		this.popup.find( '.popup__inner' ).addClass( 'popup__inner--overflow' );

		this._setData( vimeo );
	},

	_closePopup: function () {
		this.popup.removeClass( 'popup--is-open' );
		this.popup.find( '.popup__inner__video' ).remove();
		this.popup.find( '.popup__inner' ).removeClass( 'popup__inner--overflow' );
		this._changeURL( '' );
	},

	_initCache: function ( url, data ) {
		var that = this;

		this.cache[ url ] = data;
	},

	_changeURL: function ( params ) {

		var currentURL = window.location.pathname;

		var url = "";

		if ( params !== "" ) {
			url = currentURL + '?' + params;
		} else {
			url = currentURL;
		}

		if ( history.pushState ) {
			history.pushState( null, null, url );
		}

		return url;
	},

	_loadData: function ( url, params ) {
		var that = this;

		$( '.loader' ).addClass( 'loader--is-active' );

		if ( this.cache[ url ] ) {
			that._setData( this.cache[ url ] );
			that._changeURL( $.param( params, true ) );
		} else {

			$.ajax( {
				url: url,
				dataType: "html",
				type: 'GET',
				context: document.body,
				success: function ( data, statut ) {
					var response = $( data );
					var profile = response.find( '.section-content--team' );

					that._initCache( url, profile );
					that._setData( profile );
					that._changeURL( $.param( params, true ) );

					$( '.loader' ).removeClass( 'loader--is-active' );
				}
			} );

		}
	},

	_setData: function ( data ) {
		this.popup.find( '.popup__inner' ).html( data );
		new EmailSpam();
		this.popup.addClass( 'popup--is-open' );
		this.isLoad = false;
	},

	_getParams: function ( param ) {
		var vars = {};
		window.location.href.replace( location.hash, '' ).replace(
			/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
			function ( m, key, value ) { // callback
				vars[ key ] = value !== undefined ? value : '';
			}
		);

		if ( param ) {
			return vars[ param ] ? vars[ param ] : null;
		}
		return vars;
	}

} );
