var Projects = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#projects' );
		this.listingProjects = this.container.find( '.listing-projects' );
		this.btnShowMaps = this.container.find( '.btn--show-maps' );

		new Filters( true );
		new LoadMore();
		new LoaderHeading();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.btnShowMaps.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			$( this ).toggleClass( 'icon-maps' );
			$( this ).toggleClass( 'icon-listing' );
			$( '.section-content--projects' ).toggleClass( 'section-content--projects--is-hide' );
			$( '.section-content--maps' ).toggleClass( 'section-content--maps--is-hide' );

			if ( $( this ).hasClass( 'icon-maps' ) ) {
				$( this ).html( $( this ).attr( 'data-see' ) );
			} else {
				$( this ).html( $( this ).attr( 'data-hide' ) );
			}
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );
