var FiltersNews = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( "#team" );

		this.isLoad = false;

		this.filters = this.container = $( ".subnav--filters" );
		this.filtersLink = this.filters.find( '.subnav__menu__item__link' );

		this.listingNews = this.container.find( '.listing-news' );

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.filtersLink.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			if ( !that.isLoad ) {
				that.filtersLink.removeClass( 'subnav__menu__item__link--current' );
				$( this ).addClass( 'subnav__menu__item__link--current' );

				var url = $( this ).attr( 'href' );

				that.isLoad = true;

				$( '.loader' ).addClass( 'loader--is-active' );

				that._getData( url );
				that._changeURL( url );
			}
		} );
	},

	_getData: function ( url ) {
		var that = this;

		$.ajax( {
			url: url,
			dataType: "html",
			type: 'GET',
			context: document.body,
			success: function ( data, statut ) {
				var response = $( data );
				var content = response.find( '.listing-news' );
				var error = response.find( '.notice' );

				$( '.notice' ).remove();
				$( '.listing-news' ).find( '.listing-news__item' ).remove();

				$( '.listing-news' ).attr( 'data-pages', content.attr( 'data-pages' ) );
				$( '.listing-news' ).attr( 'data-page', content.attr( 'data-page' ) );
				$( '.listing-news' ).attr( 'data-url', content.attr( 'data-url' ) );

				$( 'html,body' ).animate( {
					scrollTop: $( '.listing-news' ) - 50,
				}, 2000, 'easeInOutExpo' );

				if ( error.length > 0 ) {
					that._showError( error );
				} else {
					that._setListing( content );
				}

				$( '.loader' ).removeClass( 'loader--is-active' );
				that.isLoad = false;
			}
		} );
	},

	_setListing: function ( data ) {

		data.find( '.listing-news__item' ).each( function ( index ) {
			$( '.listing-news' ).append( $( this ) );
		} );
	},

	_showError: function ( error ) {
		error.insertAfter( this.filters );
	},

	_changeURL: function ( url ) {
		if ( history.pushState ) {
			history.pushState( null, null, url );
		}

		$.session.set( 'last_url', url );
		$.session.set( 'type', 'news' );
	},
} );
