var MultiSlider = Class.extend( {

	init: function ( elm ) {

		var that = this;

		this.slider = elm;
		this.slides = this.slider.find( '.slider--swiper__slides' );
		this.slide = this.slider.find( '.slider--swiper__slides__slide' );
		this.leftControls = this.slider.find( '.slider__navigation--left' );
		this.rightControls = this.slider.find( '.slider__navigation--right' );

		this.slideLength = this.slide.length;
		this.slideCloned;

		this.interval;

		this.iFlag = 0;
		this.isAnim = false;

		if ( this.slideLength > 1 ) {
			this._initEvents();
		} else {
			this.leftControls.fadeOut();
			this.rightControls.fadeOut();
		}
	},

	_initEvents: function () {
		var that = this;

		this._onResize();
		this._cloneSLide();
		this._goToSlide( this.iFlag );

		this.leftControls.on( Event.CLICK, function ( e, i ) {
			e.preventDefault();
			e.stopPropagation();

			if ( !that.isAnim ) {
				that.isAnim = true;
				that._goToPrev();
			}
		} );

		this.rightControls.on( Event.CLICK, function ( e, i ) {
			e.preventDefault();
			e.stopPropagation();

			if ( !that.isAnim ) {
				that.isAnim = true;
				that._goToNext();
			}
		} );

		W.window.on( Event.RESIZE, $.proxy( this._onResize, this ) );
	},

	_goToNext: function () {
		var that = this;

		if ( that.iFlag >= that.slideLength - 1 ) {

			that.slides.addClass( 'slider--swiper__slides--no-transition' );
			that.slideCloned.eq( 1 ).addClass( 'slider--swiper__slides__slide--pre-current' );

			setTimeout( function () {
				that.slides.css( 'left', that.slide.outerWidth( true ) );

				setTimeout( function () {
					that.slides.removeClass( 'slider--swiper__slides--no-transition' );
				}, 20 );
			}, 100 );

			setTimeout( function () {
				that.iFlag = 0;
				that._goToSlide( that.iFlag );
				that.slideCloned.eq( 1 ).removeClass( 'slider--swiper__slides__slide--pre-currentt' );
			}, 150 );

		} else {
			that.iFlag++;
			that._goToSlide( that.iFlag );
		}
	},

	_goToPrev: function () {
		var that = this;

		if ( that.iFlag <= 0 ) {

			that.slides.addClass( 'slider--swiper__slides--no-transition' );
			that.slideCloned.eq( that.slideCloned.length - 2 ).addClass( 'slider--swiper__slides__slide--pre-current' );

			var left = -( ( that.slide.outerWidth( true ) * that.slideLength ) )

			setTimeout( function () {
				that.slides.css( 'left', left );

				setTimeout( function () {
					that.slides.removeClass( 'slider--swiper__slides--no-transition' );
				}, 20 );
			}, 100 );

			setTimeout( function () {
				that.iFlag = that.slideLength - 1;
				that._goToSlide( that.iFlag );
				that.slideCloned.eq( that.slideCloned.length - 2 ).removeClass( 'slider--swiper__slides__slide--pre-current' );
			}, 150 );


		} else {
			that.iFlag--;
			that._goToSlide( that.iFlag );
		}
	},

	_cloneSLide: function () {
		var that = this;

		that.slide.eq( 0 ).clone().appendTo( that.slides );
		that.slide.eq( 1 ).clone().appendTo( that.slides );
		that.slide.eq( that.slideLength - 1 ).clone().prependTo( that.slides );
		that.slide.eq( that.slideLength - 2 ).clone().prependTo( that.slides );

		that.slideCloned = that.slider.find( '.slide' );

	},

	_goToSlide: function ( i ) {
		var that = this;

		var active = that.slide.filter( ".slider--swiper__slides__slide--current" ),
			selected = $( that.slide[ i ] );

		that.slide.removeClass( 'slider--swiper__slides__slide--current' );
		selected.addClass( 'slider--swiper__slides__slide--current' );

		that.slides.css( {
			'left': -i * that.slide.outerWidth( true ),
		} );

		setTimeout( function () {
			that.isAnim = false;
			clearTimeout( that.interval );
			that._autoSlide();
		}, 250 )

	},

	_autoSlide: function () {
		var that = this;

		this.interval = setInterval( function () {

			that._goToNext();

		}, 8000 );
	},

	_onResize: function () {
		var that = this;

		that.slides.find( '.slider--swiper__slides__slide' ).css( {
			"width": that.slider.outerWidth(),
			"float": "left",
			"position": "relative",
		} );

		that.slides.css( {
			"width": ( ( that.slideLength + 4 ) * that.slider.outerWidth() ),
			"transform": "translate(" + ( 2 * -that.slider.outerWidth() ) + "px ,0)",
			'left': -that.iFlag * that.slider.outerWidth()
		} );
	}

} );
