var Parallax = Class.extend( {

	init: function ( options ) {

		this.container = $( '.heading__cover, .heading__slider .slider__slides' );
		this.isAnim = false;

		this._initEvents();

	},

	_initEvents: function () {

		var that = this;

		if ( device === 'desktop' ) {
			$( window ).on( 'scroll', function ( e ) {
				var scrollTop = $( this ).scrollTop();
				that.container.css( 'top', -scrollTop / 2 );
			} );
		}
	},
} );
