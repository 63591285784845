var Team = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#team' );
		this.navigation = this.container.find( '.navigation-team' );
		this.director = this.container.find( '.listing-team--director' );
		this.member = this.container.find( '.listing-team--member' );
		this.block = this.container.find( '.listing-block' );
		this.filters = this.container.find( '.filters--team' );
		this.iDirector = this.director.find( '.listing-team__item' ).length;

		new Animate();
		new Popup();
		new Parallax();
		new LoaderHeading();
		new Hash();

		if ( this.filters.length > 0 ) {
			new Sticky( {
				element: this.filters,
				parent: that.container.find( '.section-content--members .center' )
			} );
		}

		this._initEvents();
		this.resize();
	},

	_initEvents: function () {
		var that = this;

		this.member.find( '.listing-team__item' ).matchHeight( {
			byRow: false,
			property: 'height',
		} );

		this.block.find( '.listing-block__item' ).matchHeight( {
			byRow: true,
			property: 'min-height',
		} );

		this.navigation.find( '.navigation-team__item--column' ).matchHeight( {
			byRow: true,
			property: 'min-height',
		} );

		this.block.find( '.listing-block__item .listing-block__item__title' ).matchHeight( {
			byRow: true,
			property: 'min-height',
		} );

		this.navigation.find( '.navigation-team__item--column--height a' ).css( 'line-height', this.navigation.find( '.navigation-team__item--column' ).outerHeight() + 'px' );

		this.member.mixItUp( {
			controls: {
				enable: true,
				toggleFilterButtons: false,
				activeClass: 'filters__link--active'
			},
			layout: {
				display: 'block'
			},
			selectors: {
				filter: '.filters__link'
			}
		} );
	},

	resize: function () {
		var that = this;
		var height = 0;
		$.fn.matchHeight._update();
		this.navigation.find( '.navigation-team__item--column--height a' ).css( 'line-height', this.navigation.find( '.navigation-team__item--column' ).outerHeight() + 'px' );

		$( '.js-team-match' ).eq( 1 ).css( 'width', $( '.js-team-match' ).eq( 0 ).find( 'li' ).eq( 0 ).find( 'a' ).width() );


		$( '.js-team-title' ).matchHeight( {
			byRow: false,
			property: 'height',
		} );
	},

	destroy: function () {

	}

} );
