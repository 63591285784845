var Header = Class.extend( {

	init: function () {

		this.container = $( '.header' );
		this.height = this.container.outerHeight();
		this.heading = $( '.heading' );
		this.searchBar = $( '.form--searchbar' );
		this.btnOpenBox = this.container.find( '.btn--icon__search' );
		this.mobile = this.container.find( '.btn--mobile' );
		this.LangBtn = this.container.find( '.language-switcher__button' );

		new EmailSpam();
		new LoaderHeading();
		new Placeholder();

		this._initEvents();
		this._onMouseWheel();
		this.resize();
	},

	_initEvents: function () {

		var that = this;

		if ( $( '.language-switcher__listing__item' ).length < 1 ) {
			this.LangBtn.find( '.icon' ).remove();
		}

		this.btnOpenBox.on( Event.CLICK, function ( e ) {
			$( '#container' ).toggleClass( 'searchbar-is-open' );
			that.container.find( '.form--searchbar' ).toggleClass( 'form--searchbar--is-close' );
			that.container.find( '.form--searchbar .input--search' ).focus();

			that._setHeight();
		} );

		this.LangBtn.on( Event.CLICK, function ( e ) {
			$( this ).parent().toggleClass( 'language-switcher--active' );
			$( this ).find( '.icon' ).toggleClass( 'icon-arrow--up' );
		} );

		this.searchBar.on( Event.SUBMIT, function ( e ) {
			if ( $( this ).find( '.input--search' ).val() === $( this ).find( '.input--search' ).attr( 'data-placeholder' ) ) {
				e.preventDefault();
				e.stopPropagation();
			}
		} );

		this.mobile.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			$( this ).toggleClass( 'btn--mobile--is-active' );
			that.container.toggleClass( 'header--mobile--is-open' );
		} );

		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_setHeight: function () {
		var that = this;

		if ( $( '#container' ).hasClass( 'searchbar-is-open' ) ) {
			$( '.form--filters--is-fixed, .subnav--is-fixed' ).css( 'top', this.height * 2 );
		} else {
			$( '.form--filters--is-fixed, .subnav--is-fixed' ).css( 'top', this.height );
		}
	},

	_onMouseWheel: function () {
		var that = this;
		var scrollTop = $( window ).scrollTop();

		if ( this.heading.length > 0 ) {
			if ( scrollTop >= this.heading.outerHeight() - ( 1.5 * this.container.height() ) ) {
				that.container.addClass( 'header--with-bg' );
			} else {
				that.container.removeClass( 'header--with-bg' );
			}
		} else {
			that.container.addClass( 'header--with-bg' );
		}

		if ( !that.container.hasClass( 'header--mobile--is-open' ) ) {
			this._hasScroll( scrollTop );
		}
	},

	_closeMenuMobile: function () {
		var that = this;

		that.container.find( '.btn--mobile' ).removeClass( 'btn--mobile--is-active' );
		that.container.removeClass( 'header--mobile--is-open' );
	},

	_hasScroll: function ( st ) {
		var that = this;

		// Make sure they scroll more than delta
		if ( Math.abs( that.lastScrollTop - st ) <= 5 )
			return;

		// If they scrolled down and are past the navbar, add class .header--is-hide.
		// This is necessary so you never see what is "behind" the navbar.
		if ( st > that.lastScrollTop && st > 125 ) {
			// Scroll Down
			that.container.addClass( 'header--is-hide' );
			$( '#container' ).removeClass( 'searchbar-is-open' );
			that.container.find( '.form--searchbar' ).addClass( 'form--searchbar--is-close' );
			that.LangBtn.parent().removeClass( 'language-switcher--active' );
			that.LangBtn.find( '.icon' ).removeClass( 'icon-arrow--up' );
		} else {
			// Scroll Up
			if ( st + $( window ).height() < $( document ).height() ) {
				that.container.removeClass( 'header--is-hide' );
			}
		}

		that.lastScrollTop = st;
	},

	resize: function () {
		var that = this;
		this.height = this.container.outerHeight();

		if ( W.ww < 1300 ) {
			$( '.header__navigation--primary' ).css( 'height', W.wh );
		} else {
			$( '.header__navigation--primary' ).css( 'height', 'auto' );
			that._closeMenuMobile();
		}
	},

} );
