var LoadMore = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".listing-more" );
		this.isLoad = false;

		this._initEvents();
	},

	_initEvents: function () {
		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_onMouseWheel: function () {
		var that = this;

		var offset = $( '.listing-more' ).offset().top + ( $( '.listing-more' ).outerHeight() - W.wh );
		var scrollTop = $( window ).scrollTop();

		if ( $( '.content' ).attr( 'id' ) === "projects" && $( '.section-content--maps' ) && !$( '.section-content--maps' ).hasClass( 'section-content--maps--is-hide' ) ) {
			$( '.section-content--loader' ).addClass( 'section-content--loader--is-hide' );
			return;
		}

		if ( offset <= scrollTop && !this.isLoad && $( '.notice' ).length < 1 && ( parseInt( this.container.attr( 'data-pages' ) ) >= parseInt( this.container.attr( 'data-page' ) ) ) ) {

			this.isLoad = true;
			$( '.section-content--loader' ).removeClass( 'section-content--loader--is-hide' );

			$.ajax( {
				url: that.container.attr( 'data-url' ),
				dataType: "html",
				type: 'GET',
				context: document.body,
				success: function ( data, statut ) {
					var response = $( data );
					var content = response.find( '.listing-more' );
					var error = response.find( '.notice' );

					if ( error.length === 0 ) {
						that._setListing( content );

						$( '.listing-more' ).attr( 'data-pages', content.attr( 'data-pages' ) );
						$( '.listing-more' ).attr( 'data-page', content.attr( 'data-page' ) );
						$( '.listing-more' ).attr( 'data-url', content.attr( 'data-url' ) );
					}

					$( '.section-content--loader' ).addClass( 'section-content--loader--is-hide' );
					that.isLoad = false;
				}
			} );
		}
	},

	_setListing: function ( data ) {

		data.find( 'li' ).each( function ( index ) {
			$( '.listing-more' ).append( $( this ) );
		} );

	},
} );
