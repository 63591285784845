var Single = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#single' );

		new Slider();
		new Animate();
		new Parallax();
		new LoaderHeading();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );
