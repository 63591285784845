var Contact = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#contact' );
		this.subject = this.container.find( '.subject-wrap select' );
		this.jobs = this.container.find( '.listing-jobs__item__title' );
		this.identification = this.container.find( 'input.identification' );

		new Subnav();
		new LoaderHeading();
		new Popup();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		$( 'select' ).dropkick( {
			mobile: true
		} );

		$( '.upload-wrap' ).each( function () {
			$( this ).find( '[type="file"]' ).ezdz( {
				text: $( this ).find( 'label' ).eq( 0 ).text(),
				reject: function ( file, errors ) {
					if ( errors.mimeType ) {
						alert( file.name + ' must be an image.' );
					}

					if ( errors.maxWidth ) {
						alert( file.name + ' must be width:600px max.' );
					}

					if ( errors.maxHeight ) {
						alert( file.name + ' must be height:400px max.' );
					}
				},
			} );
		} );

		$( '.list-checkbox-wrap ul li input:checkbox' ).on( 'change', function ( e ) {
			if ( $( this ).is( ':checked' ) ) {
				$( this ).parent().addClass( 'is-checked' );
			} else {
				$( this ).parent().removeClass( 'is-checked' );
			}
		} );

		$( '.list-radio-wrap ul li input:radio' ).on( 'change', function ( e ) {
			$( this ).parents( 'ul' ).find( 'li label' ).removeClass( 'is-checked' );

			if ( $( this ).is( ':checked' ) ) {
				$( this ).parent().addClass( 'is-checked' );
			} else {
				$( this ).parent().removeClass( 'is-checked' );
			}
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );
