var Block = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( '.block' );
		this.thumb = this.container.find( '.block__thumb' );
		this.img = this.thumb.find( 'img' );

		this._initEvents();
		this._onResize();
	},

	_initEvents: function () {
		var that = this;

		W.window.on( Event.RESIZE, $.proxy( this._onResize, this ) );
	},

	_onResize: function () {


		this.thumb.each( function () {
			if ( !$( this ).parent().hasClass( 'section-content--numbers' ) ) {
				new SetFullSize( {
					element: $( this ).find( 'img' ),
					parent: $( this )
				} );
			}
		} );
	}

} );
